import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState: { messages: IMessage[] } = { messages: [] };

export const addMessage = createAction<IMessage>("app/add-message");
export const removeMessage = createAction<IMessage>("app/remove-message");

export default createReducer(initialState, builder => {
  builder
    .addCase(addMessage, (state, action) => {
      const m = { ...action.payload, date: new Date().toISOString() };
      state.messages = [...state.messages, m];
    })
    .addCase(removeMessage, (state, action) => {
      state.messages = state.messages.filter(m => m.message !== action.payload.message);
    });
});

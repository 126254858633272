// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-password-forgot-tsx": () => import("./../../../src/pages/account/password/forgot.tsx" /* webpackChunkName: "component---src-pages-account-password-forgot-tsx" */),
  "component---src-pages-account-password-reset-tsx": () => import("./../../../src/pages/account/password/reset.tsx" /* webpackChunkName: "component---src-pages-account-password-reset-tsx" */),
  "component---src-pages-account-request-activation-tsx": () => import("./../../../src/pages/account/request-activation.tsx" /* webpackChunkName: "component---src-pages-account-request-activation-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-otp-tsx": () => import("./../../../src/pages/login/otp.tsx" /* webpackChunkName: "component---src-pages-login-otp-tsx" */),
  "component---src-pages-login-recovery-tsx": () => import("./../../../src/pages/login/recovery.tsx" /* webpackChunkName: "component---src-pages-login-recovery-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-settings-account-delete-tsx": () => import("./../../../src/pages/settings/account/delete.tsx" /* webpackChunkName: "component---src-pages-settings-account-delete-tsx" */),
  "component---src-pages-settings-account-email-update-tsx": () => import("./../../../src/pages/settings/account/email/update.tsx" /* webpackChunkName: "component---src-pages-settings-account-email-update-tsx" */),
  "component---src-pages-settings-account-password-change-tsx": () => import("./../../../src/pages/settings/account/password/change.tsx" /* webpackChunkName: "component---src-pages-settings-account-password-change-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */),
  "component---src-pages-settings-security-recovery-tsx": () => import("./../../../src/pages/settings/security/recovery.tsx" /* webpackChunkName: "component---src-pages-settings-security-recovery-tsx" */),
  "component---src-pages-settings-security-totp-tsx": () => import("./../../../src/pages/settings/security/totp.tsx" /* webpackChunkName: "component---src-pages-settings-security-totp-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}


import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = { authenticated: false, token: "", requires2fa: false };

export const loginAction = createAction("auth/login");
export const logoutAction = createAction("auth/logout");
export const requires2faAction = createAction<string>("auth/requires2fa");

export default createReducer(initialState, builder => {
  builder
    .addCase(loginAction, (state, action) => {
      state.authenticated = true;
      state.token = "";
    })
    .addCase(logoutAction, (state, action) => {
      state.authenticated = false;
      state.token = "";
    })
    .addCase(requires2faAction, (state, action) => {
      state.requires2fa = true;
      state.token = action.payload;
    });
});

import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  email: "",
  totpEnabled: false,
  recoveryCodesGenerated: false,
};

export const setSettingsState = createAction<typeof initialState>("settings/init");
export const updateEmail = createAction<string>("settings/update-email");
export const updateTotpEnabled = createAction<boolean>("settings/update-totp");
export const updateRecoveryCodesGenerated = createAction<boolean>("settings/update-recovery");

export default createReducer(initialState, builder => {
  builder
    .addCase(setSettingsState, (state, action) => {
      state.initialized = true;
      state.email = action.payload.email;
      state.totpEnabled = action.payload.totpEnabled;
      state.recoveryCodesGenerated = action.payload.recoveryCodesGenerated;
    })
    .addCase(updateEmail, (state, action) => {
      state.email = action.payload;
    })
    .addCase(updateTotpEnabled, (state, action) => {
      state.totpEnabled = action.payload;
    })
    .addCase(updateRecoveryCodesGenerated, (state, action) => {
      state.recoveryCodesGenerated = action.payload;
    });
});
